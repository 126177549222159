let lastKnownScrollPosition = 0;
let ticking = false;

function initPlayer() {
  const playerContainer = document.getElementById("player");
  const playerLinks =
    document.querySelectorAll<HTMLLinkElement>("[data-player]");

  document
    .getElementById("player-close")
    ?.addEventListener("click", (event) => {
      event.preventDefault();
      playerContainer!.innerHTML = "";
    });

  playerLinks.forEach((playerLinkElement) => {
    playerLinkElement.addEventListener("click", (event) => {
      playerContainer!.innerHTML = "";
      event.preventDefault();
      const playerIframeContent: string =
        playerLinkElement.querySelector("[data-player-content]")?.textContent ||
        "";
      playerContainer!.innerHTML = playerIframeContent;
    });
  });
}

function init() {
  initPlayer();
  document.addEventListener("scroll", (event) => {
    lastKnownScrollPosition = window.scrollY;

    if (!ticking) {
      window.requestAnimationFrame(() => {
        ticking = false;
      });

      ticking = true;
    }
  });
}

document.addEventListener("DOMContentLoaded", init);
